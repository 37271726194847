import { lazy } from "react";
import { type QueryClient } from "@tanstack/react-query";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";

export interface RouterContext {
	auth: {
		isAuthenticated: () => boolean;
	};
	breadcrumbs?: {
		crumb?: () => string | undefined;
	};
	legal: {
		acceptingPrivacyPolicy: boolean;
		acceptingTermsConditions: boolean;
	};
	queryClient: QueryClient;
}

// This is to only show the devtools in development mode
const TanStackRouterDevtools = import.meta.env.PROD
	? () => null // Render nothing in production
	: lazy(() =>
			// Lazy load in development
			import("@tanstack/router-devtools").then((res) => ({
				default: res.TanStackRouterDevtools,
			})),
		);

export const rootRoute = createRootRouteWithContext<RouterContext>()({
	component: () => (
		<>
			<Outlet />
			<TanStackRouterDevtools position="bottom-right" />
		</>
	),
});
