import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { getSiteConfigBaseQueryOptions } from "~/lib/api/site-config";
import { applicationContext } from "~/lib/context/application-context";

import { rootRoute } from "../root";

import { dashboardRoute } from "./global";

export const tenantRoute = createRoute({
	beforeLoad: async ({ params }) => {
		// Make sure the tenant is always lowercase
		if (params.tenant.toLowerCase() !== params.tenant) {
			window.location.href = window.location.href.toLowerCase();

			return;
		}

		// Set the tenant in application context, so that we can get access to it outside on react scope
		applicationContext.tenant = params.tenant;
	},
	component: lazyRouteComponent(() => import("~/components/ThemedAppContainer"), "ThemedAppContainer"),
	getParentRoute: () => rootRoute,
	loader: async ({ context }) => {
		await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());
	},
	path: "$tenant",
	validateSearch: z.object({
		redirect: z.string().optional(),
	}),
});

export const tenantNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: dashboardRoute.to,
		});
	},
	getParentRoute: () => tenantRoute,
	path: "*",
});
