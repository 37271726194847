/**
 * Global routes - these are routes the are direct children of /$tenant
 */

import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { t } from "i18next";

import { LeaderboardKey } from "~/enums";
import { globalConfig } from "~/lib/config";

import { applicationLayout } from "./application";

export const dashboardRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Dashboard"), "DashboardPage"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "/",
});

export const submitContentRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/SubmitContent"), "SubmitContentPage"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "submit-content",
	staticData: {
		crumb: () => t("navigation.submit-content"),
	},
});

export const submitContentNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: submitContentRoute.to,
		});
	},
	getParentRoute: () => submitContentRoute,
	path: "*",
});

export const leaderboardRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Leaderboards"), "LeaderboardsPage"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "leaderboard",
	staticData: {
		crumb: () => t("navigation.leaderboard.root"),
	},
});

export const leaderboardDetailRoute = createRoute({
	beforeLoad: ({ params }) => {
		nprogress.start();

		if (!Object.values(LeaderboardKey).includes(params.leaderboardKey)) {
			throw redirect({
				params: {
					...params,
					leaderboardKey: LeaderboardKey.MONTHLY,
				},
				to: leaderboardDetailRoute.to,
			});
		}
	},
	component: lazyRouteComponent(() => import("~/pages/Leaderboard"), "LeaderboardPage"),
	getParentRoute: () => leaderboardRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "$leaderboardKey",
});

export const leaderboardDetailNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params: {
				...params,
				leaderboardKey: LeaderboardKey.MONTHLY,
			},
			to: leaderboardDetailRoute.to,
		});
	},
	getParentRoute: () => leaderboardDetailRoute,
	path: "*",
});

export const settingsRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Settings"), "SettingsPage"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "settings",
	staticData: {
		crumb: () => t("navigation.settings"),
	},
});

export const settingNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: settingsRoute.to,
		});
	},
	getParentRoute: () => settingsRoute,
	path: "*",
});

export const searchRoute = createRoute({
	beforeLoad: ({ params }) => {
		nprogress.start();

		if (globalConfig.get().featureFlags.enableSearch === false) {
			throw redirect({
				params,
				to: dashboardRoute.to,
			});
		}
	},
	component: lazyRouteComponent(() => import("~/pages/Search"), "SearchPage"),
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "search",
	staticData: {
		crumb: () => t("navigation.search"),
	},
});

export const searchNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: searchRoute.to,
		});
	},
	getParentRoute: () => searchRoute,
	path: "*",
});
