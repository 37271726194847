import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";

import { privateRoute } from "./private";
import { signInRoute } from "./public";

export const applicationLayout = createRoute({
	beforeLoad: async ({ params, context, location }) => {
		if (!context.auth) throw new Error("Auth context not found");

		if (!context.auth.isAuthenticated()) {
			throw redirect({
				params: {
					tenant: params.tenant,
				},
				search: {
					redirect: location.href,
				},
				to: signInRoute.to,
			});
		}
	},
	component: lazyRouteComponent(() => import("~/layouts/Main"), "MainLayout"),
	getParentRoute: () => privateRoute,
	id: "application-layout",
});
