export const TOKEN_COOKIE_NAME = "evotixLearn" as const;

export const DEFAULT_HEARTBEAT_INTERVAL = 60000;

export const FILE_UPLOAD_MIME_TYPES = ["audio/*", "video/*", "image/*"];

export const LIVE_QUERY_STALE_TIME = 0;

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_DATE_FORMAT = "L";

export const DEFAULT_DATETIME_FORMAT = "L LT";
