import { Anchor } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";

import { ReactComponent as ImageServiceUnavailable } from "~/assets/images/states/default/barricade-state.svg";
import { EmptyState } from "~/components/EmptyState";

import { PlaceholderLayout } from "../layouts/Placeholder";

const STATUS_PAGE_URL = "https://status.evotix.com";

export const ServiceUnavailable = () => {
	const { t } = useTranslation();

	return (
		<PlaceholderLayout>
			<EmptyState
				description={
					<Trans
						components={{
							CustomLink: <Anchor fz="sm" href={STATUS_PAGE_URL} />,
						}}
						i18nKey="app.service-unavailable.description"
					/>
				}
				image={ImageServiceUnavailable}
				imageSize={250}
				title={t("app.service-unavailable.title")}
				w={550}
			/>
		</PlaceholderLayout>
	);
};
