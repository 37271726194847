import { z } from "zod";

import { PostType } from "~/enums";

import { baseLearningGroupChild } from "../shared.schema";

export const missionsSchema = z.array(
	baseLearningGroupChild
		.extend({
			ancestors: z
				.object({
					levelId: z.number(),
					pathwayId: z.number(),
				})
				.nullable()
				.catch(null),
			awardIds: z.array(z.number()).nullable().optional(),
			dueDate: z.string().datetime({ offset: true }).nullable(),
			type: z.literal(PostType.MISSION),
		})
		.omit({ order: true })
		.passthrough(),
);
