import { z } from "zod";

import { PasswordStrength } from "~/lib/constants/password";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";

const identityProviderSchema = z.object({
	authUrl: z.string().url().nullable(),
	id: z.string(),
	name: z.string(),
});

const siteConfigSchema = z
	.object({
		canSignup: z.boolean(),
		canUserEmailLogin: z.boolean(),
		configuredLinks: z
			.array(
				z.object({
					label: z.string(),
					url: z.string(),
				}),
			)
			.nullable()
			.catch(null),
		hasThirdPartyIntegration: z.boolean(),
		identityProviders: z.array(identityProviderSchema).catch([]),
		language: z.object({
			default: z.string().nullable(),
			supported: z.array(z.string().nullable()),
		}),
		logo: z.object({
			alt: z.string().nullable(),
			url: cacheableMediaUrlSchema(true).nullable(),
		}),
		name: z.string(),
		passwordStrength: z.nativeEnum(PasswordStrength).default(PasswordStrength.GOOD),
		privacyPolicy: z.boolean(),
		termsConditions: z.boolean(),
		theme: z.object({
			action: z.string().nullable(),
			backgroundUrl: z.string().nullable(),
			brand: z.string().nullable(),
		}),
		uploadRestrictions: z.object({
			accept: z.array(z.string()),
			maxSize: z.string(),
		}),
	})
	.passthrough();

type SiteConfigResponse = z.infer<typeof siteConfigSchema>;

export { siteConfigSchema, type SiteConfigResponse };
