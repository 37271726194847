import { z } from "zod";

import { PostType } from "~/enums";

export const sharedResourceSchema = z.object({
	description: z.string().nullable(),
	id: z.number(),
	title: z.string(),
	type: z.nativeEnum(PostType),
});
