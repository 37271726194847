import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const AppVersion = () => {
	const { t } = useTranslation();

	return (
		<Text c="dimmed" size="xs" ta="center">
			{t("app.version", { version: WATT_VERSION })}
		</Text>
	);
};
