import { Box, Stack } from "@mantine/core";

import classes from "./PlaceholderLayout.module.scss";

export const PlaceholderLayout = ({ children }: { children: React.ReactNode }) => {
	return (
		<Box className={classes["root"]} component="main" h="100%" id="placeholder-layout">
			<Stack align="center">{children}</Stack>
		</Box>
	);
};
