import { type ReactElement } from "react";
import { ConditionalVisible } from "@evotix/napier-ui-common-components";
import { Box, Center, Text } from "@mantine/core";

import { ReactComponent as ImageNotFound } from "~/assets/images/states/default/missing-state.svg";

export interface EmptyStateProps {
	title: string;
	description?: string | ReactElement;
	image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	w?: number;
	imageSize?: number;
}

export const EmptyState = ({
	title,
	description,
	image: Image = ImageNotFound,
	imageSize = 150,
	w = 350,
}: EmptyStateProps) => {
	return (
		<Box maw={w} px="xs">
			<Center>
				<Image height={imageSize} role="presentation" width={imageSize} />
			</Center>

			<Text fw={700} fz="md" mt="lg" ta="center">
				{title}
			</Text>

			<ConditionalVisible on={description}>
				<Text fz="sm" mt="sm" ta="center">
					{description}
				</Text>
			</ConditionalVisible>
		</Box>
	);
};
