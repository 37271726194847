import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { napierMiddlewareStack, napierNativeCacheAddon } from "@evotix/napier-ui-common-native-bridge/utilities";
import i18next from "i18next";
import baseWretch from "wretch";
import { formDataAddon, queryStringAddon } from "wretch/addons";

import { applicationContext } from "~/lib/context/application-context";
import { ensureCookieDeletion } from "~/lib/offline/authentication";
import { router } from "~/router";

import { globalConfig } from "../../config";
import { queryClient } from "../../queryClient";

import { getBearerToken } from "./utilities";

export const coreWretch = () =>
	baseWretch(globalConfig.get().endpointUrl)
		.middlewares(napierMiddlewareStack)
		.addon(queryStringAddon)
		.addon(formDataAddon)
		.addon(napierNativeCacheAddon)
		/** Required to ensure backend cookie is set correctly */
		.options({ credentials: "include" });

export const wretch = () =>
	coreWretch()
		.headers({
			"Accept-Language": i18next.language,
			"X-Tenant-Key": applicationContext.tenant,
		})
		/** Global handler for all 401 (unauthroised) error responses - should auto redirect to the sign-in page */
		.catcher(401, async (error) => {
			// Ensure the bridge is kept in sync when the user is logged out, on an unauthorised response
			await NapierBridgeCommon.setActiveUser({ token: undefined, userId: null });

			await ensureCookieDeletion();

			// TODO: do we want to pop up something indicating to the user that there auth has expired?
			await router.navigate({ from: "/$tenant/", params: true, to: "/$tenant/sign-in" });
			// Remove any data from the query cache that is not the site config
			queryClient.removeQueries({ predicate: (query) => !query.queryKey.includes("site-config") });

			return error;
		})
		.auth(getBearerToken());

export type {
	Wretch,
	Config,
	ConfiguredMiddleware,
	FetchLike,
	Middleware,
	WretchResponseChain,
	WretchOptions,
	WretchErrorCallback,
	WretchResponse,
	WretchDeferredCallback,
	WretchAddon,
} from "wretch";

export { WretchError } from "wretch/resolver";
