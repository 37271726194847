import { z } from "zod";

import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";

import { sharedResourceSchema } from "../shared.schema";

const resourceCategorySchema = sharedResourceSchema.extend({
	children: z.array(
		z.object({ id: z.number(), thumbnailUrl: cacheableMediaUrlSchema().nullable(), title: z.string() }),
	),
});

type ResourceCategoryResponse = z.infer<typeof resourceCategorySchema>;

export { resourceCategorySchema, type ResourceCategoryResponse };
