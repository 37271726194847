import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	useSuspenseQuery as useBaseSuspenseQuery,
	queryOptions,
} from "@tanstack/react-query";

import { PostType } from "~/enums";
import { type Parent } from "~/types/application";

import { type GetResourceResponse, getResource, getUrl } from "./resource.api";

type ResourceParent = Parent<PostType.RESOURCE, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [resource: ResourceParent];

const getQueryKey = (id: number) => ["resource", { id }] as const;

const getBaseQueryOptions = <TData = GetResourceResponse>(id: number) =>
	queryOptions<GetResourceResponse, Error, TData>({
		queryFn: () => getResource(id),
		queryKey: getQueryKey(id),
	});

const useSuspenseQuery = <TData = GetResourceResponse>(
	id: number,
	options?: Pick<UseSuspenseQueryOptions<GetResourceResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useBaseSuspenseQuery<GetResourceResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(id),
	});
};

/* v8 ignore start */
// TODO: remove v8 ignore once implemented
const getParent = (id: number) =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		id,
		type: PostType.RESOURCE,
	}) satisfies ResourceParent;
/* v8 ignore end */

export {
	useSuspenseQuery as useResourceSuspenseQuery,
	getBaseQueryOptions as getResourceBaseQueryOptions,
	getQueryKey as getResourceQueryKey,
	getParent as getResourceParent,
	getUrl as getResourceUrl,
	type Tuple as ResourceTuple,
};
