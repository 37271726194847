import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { z } from "zod";

import { getSiteConfigBaseQueryOptions } from "~/lib/api/site-config";
import { type Language, DEFAULT_LANGUAGE } from "~/lib/constants/language";
import { switchLanguage } from "~/translations/utilities";

import { dashboardRoute } from "./global";
import { tenantRoute } from "./tenant";

export const publicRoute = createRoute({
	beforeLoad: async ({ context, params, search }) => {
		if (!context.auth) throw new Error("Auth context not found");

		if (context.auth.isAuthenticated()) {
			throw redirect({
				params,
				to: search?.["redirect"] ?? dashboardRoute.to,
			});
		}

		await NapierBridgeCommon.setStatusBarLight();
	},
	getParentRoute: () => tenantRoute,
	id: "public",
	loader: async ({ context }) => {
		const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());

		const appDefaultLanguage = siteConfig.language.default ?? DEFAULT_LANGUAGE;

		await switchLanguage(appDefaultLanguage as Language);
	},
	validateSearch: z.object({
		redirect: z.string().optional(),
	}),
});

export const authenticationLayout = createRoute({
	component: lazyRouteComponent(() => import("~/layouts/Authentication"), "AuthenticationLayout"),
	getParentRoute: () => publicRoute,
	id: "authentication-layout",
});

export const signInRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Authentication/SignIn"), "SignInPage"),
	getParentRoute: () => authenticationLayout,
	path: "sign-in",
});

export const signInNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: signInRoute.to,
		});
	},
	getParentRoute: () => signInRoute,
	path: "*",
});

export const signUpRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Authentication/SignUp"), "SignUpPage"),
	getParentRoute: () => authenticationLayout,
	loaderDeps: ({ search }) => ({ search }),
	// eslint-disable-next-line sort-keys -- for some reason types break if loaderDeps appears after loader :(
	loader: async ({ params, context, deps }) => {
		const siteConfig = await context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions());

		if (siteConfig.canSignup === false) {
			throw redirect({
				params,
				search: {
					redirect: deps.search?.["redirect"],
				},
				to: signInRoute.to,
			});
		}
	},
	path: "sign-up",
});

export const signUpNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: signUpRoute.to,
		});
	},
	getParentRoute: () => signUpRoute,
	path: "*",
});

export const forgotPasswordRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Authentication/ForgotPassword"), "ForgotPasswordPage"),
	getParentRoute: () => authenticationLayout,
	path: "forgot-password",
});

export const forgotPasswordNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: forgotPasswordRoute.to,
		});
	},
	getParentRoute: () => forgotPasswordRoute,
	path: "*",
});

// TODO: decide the shape of this schema
const resetPasswordSearchSchema = z.object({ token: z.string().min(1) });

export const resetPasswordRoute = createRoute({
	beforeLoad: async ({ search, params }) => {
		const result = resetPasswordSearchSchema.safeParse(search);
		if (!result.success) {
			throw redirect({
				params,
				to: signInRoute.to,
			});
		}
	},
	component: lazyRouteComponent(() => import("~/pages/Authentication/ResetPassword"), "ResetPasswordPage"),
	getParentRoute: () => authenticationLayout,
	path: "reset-password",
	validateSearch: resetPasswordSearchSchema,
});

export const resetPasswordNotFoundRoute = createRoute({
	beforeLoad: ({ params, search }) => {
		throw redirect({
			params,
			search,
			to: resetPasswordRoute.to,
		});
	},
	getParentRoute: () => resetPasswordRoute,
	path: "*",
});
