import {
	type UseSuspenseQueryOptions,
	type UseSuspenseQueryResult,
	queryOptions,
	useSuspenseQuery as rqUseSuspenseQuery,
} from "@tanstack/react-query";

import { PostGroup } from "~/enums";
import { type ParentBase } from "~/types/application";

import { getMissions, getUrl, type GetMissionsResponse } from "./missions.api";

type MissionsParent = ParentBase<PostGroup.MISSIONS, typeof getBaseQueryOptions, typeof getUrl>;
type Tuple = [missions: MissionsParent];

const getQueryKey = () => ["learning", "missions"] as const;

const getBaseQueryOptions = <TData = GetMissionsResponse>() =>
	queryOptions<GetMissionsResponse, Error, TData>({
		queryFn: getMissions,
		queryKey: getQueryKey(),
	});

const useSuspenseQuery = <TData = GetMissionsResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetMissionsResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> =>
	rqUseSuspenseQuery<GetMissionsResponse, Error, TData>({
		queryFn: getMissions,
		queryKey: getQueryKey(),
		...options,
	});

const getParent = () =>
	({
		getQueryOptions: getBaseQueryOptions,
		getUrl,
		type: PostGroup.MISSIONS,
	}) satisfies MissionsParent;

export {
	getBaseQueryOptions as getMissionsBaseQueryOptions,
	getQueryKey as getMissionsQueryKey,
	useSuspenseQuery as useMissionsSuspenseQuery,
	getParent as getMissionsParent,
	getUrl as getMissionsUrl,
	type Tuple as MissionsTuple,
	type GetMissionsResponse as MissionsResponse,
};
