import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { ReactComponent as EvotixLogoDark } from "~/assets/evotix_logo-dark.svg";
import { AppVersion } from "~/components/AppVersion";
import { PlaceholderLayout } from "~/layouts/Placeholder";

export const IndexPage = () => {
	const { t } = useTranslation();

	return (
		<PlaceholderLayout>
			<Text size="sm">{t("app.copy")}</Text>
			<Text size="sm" ta="center">
				{t("app.loginIssue")}
			</Text>
			<AppVersion />
			<EvotixLogoDark height={40} width={80} />
		</PlaceholderLayout>
	);
};
