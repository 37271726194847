import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { Center, Loader } from "@mantine/core";
import { createRouter, lazyRouteComponent, createRoute } from "@tanstack/react-router";
import { WretchError } from "wretch/resolver";

import { queryClient } from "~/lib/queryClient";
import { IndexPage } from "~/pages";
import { hasAuthenticationCookie } from "~/utilities/has-authentication-cookie";

import { DefaultErrorComponent } from "./DefaultErrorComponent";
import { rootRoute } from "./root";
import { applicationLayout } from "./routes/application";
import {
	dashboardRoute,
	leaderboardDetailRoute,
	leaderboardRoute,
	settingsRoute,
	submitContentRoute,
	submitContentNotFoundRoute,
	settingNotFoundRoute,
	leaderboardDetailNotFoundRoute,
	searchRoute,
	searchNotFoundRoute,
} from "./routes/global";
import {
	directLevelRoute,
	directLevelIndexRoute,
	directLevelMissionRoute,
	directLevelMissionIndexRoute,
	directLevelStageRoute,
	directLevelStageNotFoundRoute,
	directMissionIndexRoute,
	directMissionRoute,
	directMissionStageRoute,
	directMissionStageNotFoundRoute,
	directStageRoute,
	directStageIndexRoute,
	directStageNotFoundRoute,
	learningIndexRoute,
	learningRoute,
	levelIndexRoute,
	levelRoute,
	missionIndexRoute,
	missionRoute,
	pathwayIndexRoute,
	pathwayRoute,
	stageRoute,
	stageNotFoundRoute,
} from "./routes/learning";
import {
	legalLayout,
	privacyPolicyNotFoundRoute,
	privacyPolicyRoute,
	termsAndConditionsNotFoundRoute,
	termsAndConditionsRoute,
} from "./routes/legal";
import { privateRoute } from "./routes/private";
import {
	awardsNotFoundRoute,
	awardsRoute,
	profileIndexRoute,
	profileNotFoundRoute,
	profileRoute,
} from "./routes/profile";
import {
	publicRoute,
	signInRoute,
	signUpRoute,
	authenticationLayout,
	forgotPasswordRoute,
	resetPasswordRoute,
	signInNotFoundRoute,
	signUpNotFoundRoute,
	forgotPasswordNotFoundRoute,
	resetPasswordNotFoundRoute,
} from "./routes/public";
import {
	resourceRoute,
	resourcesCategoryRoute,
	resourceCategoriesRoute,
	directResourceRoute,
	directResourceRouteNotFoundRoute,
	resourceNotFoundRoute,
	directResourceIndexRoute,
	resourceIndexRoute,
	resourceCategoriesIndexRoute,
	resourcesCategoryIndexRoute,
	directResourceCategoryRoute,
	directResourceCategoryRouteNotFoundRoute,
	directResourceCategoryIndexRoute,
} from "./routes/resources";
import { tenantRoute, tenantNotFoundRoute } from "./routes/tenant";
import { ServiceUnavailable } from "./ServiceUnavailable";

export const indexRoute = createRoute({
	component: () => <IndexPage />,
	getParentRoute: () => rootRoute,
	path: "/",
});

export const routeTree = rootRoute.addChildren([
	indexRoute,
	tenantRoute.addChildren([
		publicRoute.addChildren([
			authenticationLayout.addChildren([
				signInRoute.addChildren([signInNotFoundRoute]),
				signUpRoute.addChildren([signUpNotFoundRoute]),
				forgotPasswordRoute.addChildren([forgotPasswordNotFoundRoute]),
				resetPasswordRoute.addChildren([resetPasswordNotFoundRoute]),
			]),
		]),
		privateRoute.addChildren([
			applicationLayout.addChildren([
				dashboardRoute,
				submitContentRoute.addChildren([submitContentNotFoundRoute]),
				leaderboardRoute.addChildren([leaderboardDetailRoute.addChildren([leaderboardDetailNotFoundRoute])]),
				settingsRoute.addChildren([settingNotFoundRoute]),
				profileRoute.addChildren([
					profileNotFoundRoute,
					profileIndexRoute,
					awardsRoute.addChildren([awardsNotFoundRoute]),
				]),
				resourceCategoriesRoute.addChildren([
					resourceCategoriesIndexRoute,
					resourcesCategoryRoute.addChildren([
						resourcesCategoryIndexRoute,
						resourceRoute.addChildren([resourceIndexRoute, resourceNotFoundRoute]),
						directResourceRoute.addChildren([directResourceIndexRoute, directResourceRouteNotFoundRoute]),
					]),
					directResourceCategoryRoute.addChildren([
						directResourceCategoryIndexRoute,
						directResourceCategoryRouteNotFoundRoute,
					]),
				]),
				learningRoute.addChildren([
					learningIndexRoute,
					pathwayRoute.addChildren([
						pathwayIndexRoute,
						levelRoute.addChildren([
							levelIndexRoute,
							missionRoute.addChildren([missionIndexRoute, stageRoute.addChildren([stageNotFoundRoute])]),
						]),
					]),
				]),
				directLevelRoute.addChildren([
					directLevelIndexRoute,
					directLevelMissionRoute.addChildren([
						directLevelMissionIndexRoute,
						directLevelStageRoute.addChildren([directLevelStageNotFoundRoute]),
					]),
				]),
				directMissionRoute.addChildren([
					directMissionIndexRoute,
					directMissionStageRoute.addChildren([directMissionStageNotFoundRoute]),
				]),
				directStageRoute.addChildren([directStageIndexRoute, directStageNotFoundRoute]),
				searchRoute.addChildren([searchNotFoundRoute]),
			]),
			legalLayout.addChildren([
				termsAndConditionsRoute.addChildren([termsAndConditionsNotFoundRoute]),
				privacyPolicyRoute.addChildren([privacyPolicyNotFoundRoute]),
			]),
			tenantNotFoundRoute,
		]),
	]),
]);

export const router = createRouter({
	context: {
		auth: {
			isAuthenticated: () => hasAuthenticationCookie(),
		},
		legal: {
			acceptingPrivacyPolicy: false,
			acceptingTermsConditions: false,
		},
		queryClient,
	},
	defaultErrorComponent: ({ error, reset, info }) => {
		if (error instanceof WretchError && error.response.status === 503) {
			return <ServiceUnavailable />;
		}

		return DefaultErrorComponent({ error, info, reset });
	},
	defaultNotFoundComponent: lazyRouteComponent(() => import("~/pages/NotFound"), "NotFoundPage"),
	defaultPendingComponent: () => (
		<Center h="100%">
			<Loader />
		</Center>
	),
	routeTree,
});

NapierBridgeCommon.addListener("appUrlOpen", async ({ url }) => {
	const urlObject = new URL(url);
	await router.navigate({ replace: true, to: `${urlObject.pathname}${urlObject.search}` });
});
