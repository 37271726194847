import { NapierBridgeCommon } from "@evotix/napier-ui-common-native-bridge";
import { type QueryKey } from "@tanstack/react-query";

import { globalConfig } from "~/lib/config";
import { queryClient } from "~/lib/queryClient";
import { getDateSortValue } from "~/utilities/get-date-sort-value";

import { type SortDirection } from "../types";

export type OptimisticContext<TData = unknown> = {
	previousQueries: [QueryKey, TData, string][];
	toRemoveOnError: [QueryKey, string][];
	onSuccessInvalidationKeys: QueryKey[];
};

export const buildApiUrl = (urlBase: string) => new URL(urlBase, globalConfig.get().endpointUrl);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we allow it in generic
export const buildQueryKeyUrlWithParams = <TQueryKeyOptions extends Record<string, any>>(
	urlBase: string,
	queryKey: QueryKey,
) => {
	const url = buildApiUrl(urlBase);

	const queryKeyOptions = queryKey.at(-1) as TQueryKeyOptions;

	for (const [key, value] of Object.entries(queryKeyOptions)) {
		url.searchParams.append(key, value.toString());
	}

	url.searchParams.sort();

	return url;
};

export const getSafeQueryData = <
	TQuery extends [QueryKey, TData | undefined][],
	TData = unknown,
	TSafeData = Exclude<TQuery[number]["1"], undefined>,
>(
	queryData: TQuery,
	queryKey: QueryKey,
	safeData: NoInfer<TSafeData>,
): { safeData: [QueryKey, TSafeData][]; noExistingCacheKeys: QueryKey[] } => {
	if (queryData.length === 0) {
		return { noExistingCacheKeys: [queryKey], safeData: [[queryKey, safeData]] };
	}

	return {
		noExistingCacheKeys: queryData.map(([key, data]) => (data === undefined ? key : undefined)).filter(Boolean),
		safeData: queryData.map(([key, data]) => [key, (data as TSafeData) ?? safeData]),
	};
};

export const sortBasedOnDirection = <TItem extends Record<TSortKey, string>, TSortKey extends keyof TItem>(
	sortDirection: SortDirection,
	data: TItem[],
	sortKey: TSortKey,
) =>
	sortDirection === "DESC"
		? data.sort((a, b) => getDateSortValue(a[sortKey], b[sortKey]))
		: data.sort((a, b) => getDateSortValue(b[sortKey], a[sortKey]));

export const cancelQueries = async (queries: [QueryKey, unknown][]): Promise<void> => {
	const cancelQueryPromises = [];
	for (const [queryKey] of queries) {
		cancelQueryPromises.push(queryClient.cancelQueries({ queryKey, type: "all" }));
	}
	await Promise.allSettled(cancelQueryPromises);
};

export const getPreviousQueriesData = <TData extends [QueryKey, TItem][], TItem = TData[number][1]>(
	data: TData,
	urlGetter: (queryKey: QueryKey) => string,
) => {
	const previousQueriesSet = new Set<[QueryKey, TItem, string]>();

	for (const [queryKey, queryData] of data) {
		previousQueriesSet.add([queryKey, queryData, urlGetter(queryKey)]);
	}

	return Array.from(previousQueriesSet);
};

export const getCacheRemovalSet = (data: QueryKey[], urlGetter: (queryKey: QueryKey) => string) => {
	const cacheRemovalSet = new Set<[QueryKey, string]>();
	for (const queryKey of data) {
		cacheRemovalSet.add([queryKey, urlGetter(queryKey)]);
	}

	return Array.from(cacheRemovalSet);
};

export const invalidateQueriesData = async (data: QueryKey[]) => {
	const invalidationPromises = [];
	for (const queryKey of data) {
		invalidationPromises.push(
			queryClient.invalidateQueries({
				exact: true,
				queryKey,
				refetchType: "all",
				type: "all",
			}),
		);
	}
	await Promise.allSettled(invalidationPromises);
};

export const resetQueryData = async (context: OptimisticContext) => {
	for (const [queryKey, previousData, cachedUrl] of context.previousQueries) {
		queryClient.setQueryData(queryKey, previousData);
		// eslint-disable-next-line no-await-in-loop -- allows in for/of loops
		await NapierBridgeCommon.cacheContent({
			content: JSON.stringify(previousData),
			isAsset: false,
			url: cachedUrl.toString(),
		});
	}

	/**
	 * If the there was not any previous data in the cache, we would have create a new cahce data set, so we need to remove it from the queryCache
	 * We also need to remove this data from the bridge cached content
	 */
	for (const [queryKey, cachedUrl] of context.toRemoveOnError) {
		queryClient.removeQueries({ queryKey, type: "all" });
		// eslint-disable-next-line no-await-in-loop -- allows in for/of loops
		await NapierBridgeCommon.removeCachedContent({ remoteUrl: cachedUrl.toString() });
	}

	invalidateQueriesData(context.previousQueries.map(([queryKey]) => queryKey));
};

export const handleNewCacheContentCreation = async <TData extends [QueryKey, TItem][], TItem = TData[number][1]>(
	queries: TData,
	newDataGetter: (queryData: TData[number]) => unknown,
	urlGetter: (queryKey: QueryKey) => string,
) => {
	const addCacheContentRequestSet = new Set<Promise<void>>();
	const newQueryKeys = new Set<QueryKey>();

	for (const [queryKey, queryData] of queries) {
		newQueryKeys.add(queryKey);

		const newData = newDataGetter([queryKey, queryData]);

		queryClient.setQueryData(queryKey, newData);

		addCacheContentRequestSet.add(
			NapierBridgeCommon.cacheContent({
				content: JSON.stringify(newData),
				isAsset: false,
				url: urlGetter(queryKey),
			}),
		);
	}

	await Promise.allSettled(addCacheContentRequestSet.values());

	return Array.from(newQueryKeys);
};
