import { z } from "zod";

import { PostType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";

import { contentBlockSchema } from "../../learning/stage/stage.schema";

const resourceSchema = z.object({
	commentsAllowed: z.boolean().catch(true),
	content: z.array(contentBlockSchema),
	featuredMedia: z
		.object({
			posterUrl: cacheableMediaUrlSchema().nullable(),
			type: z.string().nullable(),
			url: cacheableMediaUrlSchema().nullable(),
		})
		.nullable()
		.optional(),
	id: z.number(),
	title: z.string(),
	type: z.literal(PostType.RESOURCE),
});

type ResourceResponse = z.infer<typeof resourceSchema>;

export { resourceSchema, type ResourceResponse };
