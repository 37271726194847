/**
 * Routes for the profile page.
 */

import { nprogress } from "@mantine/nprogress";
import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router";
import { t } from "i18next";
import { z } from "zod";

import { applicationLayout } from "./application";

export const profileRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	getParentRoute: () => applicationLayout,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	onStay: () => {
		nprogress.reset();
	},
	path: "profile",
	staticData: {
		crumb: () => t("navigation.profile.root"),
	},
});

export const profileIndexRoute = createRoute({
	component: lazyRouteComponent(() => import("~/pages/Profile"), "ProfilePage"),
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	path: "/",
	validateSearch: z.object({
		tab: z
			.union([z.literal("completed"), z.literal("bookmarks")])
			.optional()
			.default("completed"),
	}),
});

export const profileNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			search: {
				tab: "completed",
			},
			to: profileIndexRoute.to,
		});
	},
	getParentRoute: () => profileRoute,
	path: "*",
});

export const awardsRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Profile/Awards"), "AwardsPage"),
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "awards",
	staticData: {
		crumb: () => t("navigation.profile.awards"),
	},
});

export const awardsNotFoundRoute = createRoute({
	beforeLoad: ({ params }) => {
		throw redirect({
			params,
			to: awardsRoute.to,
		});
	},
	getParentRoute: () => awardsRoute,
	path: "*",
});

export const interestsRoute = createRoute({
	beforeLoad: () => {
		nprogress.start();
	},
	component: lazyRouteComponent(() => import("~/pages/Profile/Interests"), "InterestsPage"),
	getParentRoute: () => profileRoute,
	loader: () => {
		nprogress.cleanup();
	},
	onEnter: () => {
		nprogress.complete();
	},
	path: "interests",
	staticData: {
		crumb: () => t("navigation.profile.interests"),
	},
});
