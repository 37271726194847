import { createRoute, redirect } from "@tanstack/react-router";
import { t } from "i18next";

import { LeaderboardKey } from "~/enums";
import { getUserLanguageBaseQueryOptions } from "~/lib/api/application/user-language";
import { getAwardsBaseQueryOptions } from "~/lib/api/awards";
import { getAllLeadersBaseQueryOptions } from "~/lib/api/leaderboard/all-leaders";
import { getTopLeadersBaseQueryOptions } from "~/lib/api/leaderboard/top-leaders";
import { getMissionsBaseQueryOptions } from "~/lib/api/learning/missions";
import { getPathwaysBaseQueryOptions } from "~/lib/api/learning/pathways";
import { getProfileBaseQueryOptions, getProfileQueryKey, type ProfileResponse } from "~/lib/api/profile";
import { getActivitiesBaseQueryOptions } from "~/lib/api/profile/activities";
import { getBookmarksQueryOptions } from "~/lib/api/profile/bookmarks";
import { getCompletedLearningQueryOptions } from "~/lib/api/profile/completed-learning";
import { getResourceCategoriesBaseQueryOptions } from "~/lib/api/resources/categories";
import { getLatestResourcesBaseQueryOptions } from "~/lib/api/resources/latest-resources";
import { getSiteConfigBaseQueryOptions, getSiteConfigQueryKey, type SiteConfigResponse } from "~/lib/api/site-config";
import { DEFAULT_LANGUAGE, type Language } from "~/lib/constants/language";
import { UseGainsightLogin } from "~/lib/external/gainsight";
import { switchLanguage } from "~/translations/utilities";

import { type RouterContext } from "../root";

import { privacyPolicyRoute, termsAndConditionsRoute } from "./legal";
import { tenantRoute } from "./tenant";

const resetLegalContext = ({ context }: { context: RouterContext | undefined }) => {
	if (context) {
		context.legal.acceptingPrivacyPolicy = false;
		context.legal.acceptingTermsConditions = false;
	}
};

const shouldRedirectToLegalPage = (
	siteConfigCondition: boolean | undefined,
	profileCondition: boolean | null | undefined,
	context: RouterContext["legal"],
) =>
	siteConfigCondition === true &&
	profileCondition !== undefined &&
	profileCondition !== true &&
	!context.acceptingTermsConditions &&
	!context.acceptingPrivacyPolicy;

export const privateRoute = createRoute({
	beforeLoad: async ({ params, context, location }) => {
		const siteConfig = context.queryClient.getQueryData<SiteConfigResponse>(getSiteConfigQueryKey());
		const profile = context.queryClient.getQueryData<ProfileResponse>(getProfileQueryKey());

		if (shouldRedirectToLegalPage(siteConfig?.termsConditions, profile?.acceptedTermsConditions, context.legal)) {
			context.legal.acceptingTermsConditions = true;

			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: termsAndConditionsRoute.to,
			});
		} else if (
			shouldRedirectToLegalPage(siteConfig?.privacyPolicy, profile?.acceptedPrivacyPolicy, context.legal)
		) {
			context.legal.acceptingPrivacyPolicy = true;
			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: privacyPolicyRoute.to,
			});
		}
	},
	getParentRoute: () => tenantRoute,
	id: "private",
	loader: async ({ context, params, location }) => {
		// Make sure we have all the data we need
		// TODO: probably should add in some error handling here
		await Promise.allSettled([
			context.queryClient.ensureQueryData(getSiteConfigBaseQueryOptions()),
			context.queryClient.ensureQueryData(getProfileBaseQueryOptions()),
			context.queryClient.ensureQueryData(getActivitiesBaseQueryOptions()),
		]);

		const siteConfig = context.queryClient.getQueryData<SiteConfigResponse>(getSiteConfigQueryKey());
		const profile = context.queryClient.getQueryData<ProfileResponse>(getProfileQueryKey());
		const userId = profile?.id ?? 0;
		const siteConfigDefaultLanguage = siteConfig?.language.default as Language | undefined | null;

		UseGainsightLogin(profile?.id.toString(), params.tenant);

		if (shouldRedirectToLegalPage(siteConfig?.termsConditions, profile?.acceptedTermsConditions, context.legal)) {
			context.legal.acceptingTermsConditions = true;
			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: termsAndConditionsRoute.to,
			});
		} else if (
			shouldRedirectToLegalPage(siteConfig?.privacyPolicy, profile?.acceptedPrivacyPolicy, context.legal)
		) {
			context.legal.acceptingPrivacyPolicy = true;
			throw redirect({
				params,
				search: {
					redirect: location.href,
				},
				to: privacyPolicyRoute.to,
			});
		}

		/**
		 * Start prefetching global content, this enables the data to get cached for offline use when on mobile
		 * - We don't need to wait for the request to finish, as we just want to initiate the request within the queryClient
		 */
		Promise.allSettled([
			context.queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
			context.queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
			context.queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
			context.queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
			context.queryClient.prefetchQuery(getAwardsBaseQueryOptions()),
			context.queryClient.prefetchQuery(getBookmarksQueryOptions()),
			context.queryClient.prefetchQuery(getMissionsBaseQueryOptions()),
			context.queryClient.prefetchQuery(getCompletedLearningQueryOptions()),
			context.queryClient.prefetchQuery(getPathwaysBaseQueryOptions()),
			context.queryClient.prefetchQuery(getResourceCategoriesBaseQueryOptions()),
			context.queryClient.prefetchQuery(getLatestResourcesBaseQueryOptions()),
		]);

		const savedLanguage = await context.queryClient.ensureQueryData(getUserLanguageBaseQueryOptions(userId));

		const appDefaultLanguage = siteConfigDefaultLanguage ?? DEFAULT_LANGUAGE;

		if (savedLanguage) {
			await switchLanguage(savedLanguage, userId);
		} else {
			await switchLanguage(appDefaultLanguage, userId);
		}
	},
	onEnter: resetLegalContext,
	onLeave: resetLegalContext,
	onStay: resetLegalContext,
	staticData: {
		crumb: () => t("navigation.home"),
	},
});
