import { wretch } from "~/lib/external/wretch";

import { buildUrl } from "../../utilities";

import { type ResourceCategoriesResponse, resourceCategoriesSchema } from "./resource.schema";

const getUrl = () => buildUrl("resource-category", 1);

const getResourceCategories = (): Promise<ResourceCategoriesResponse> =>
	wretch()
		.headers({
			"Content-Type": "application/json",
		})
		.napierNativeCache()
		.get(getUrl())
		.json(resourceCategoriesSchema.parseAsync);

type GetResourceCategoriesResponse = Awaited<ReturnType<typeof getResourceCategories>>;

export { getResourceCategories, getUrl, type GetResourceCategoriesResponse };
