import { AppShell } from "@mantine/core";
import { ErrorComponent, type ErrorComponentProps } from "@tanstack/react-router";

export const DefaultErrorComponent = (props: ErrorComponentProps) => {
	return (
		<AppShell pb="var(--safe-area-inset-bottom)" pt="var(--safe-area-inset-top)">
			<ErrorComponent {...props} />
		</AppShell>
	);
};
