import { z } from "zod";

import { PostType } from "~/enums";
import { sanitiseUnderscoreString } from "~/utilities/sanitise-underscore-string";

const bookmarkSchema = z.object({
	childIds: z.array(z.number()).nullable().catch([]),
	dateModified: z.string().datetime({ offset: true }),
	description: z.string().nullable().catch(null),
	id: z.number(),
	smi: z.number().nullable().catch(null),
	thumbnailUrl: z.string().url().nullable().catch(null),
	title: z.string().transform(sanitiseUnderscoreString),
	type: z.nativeEnum(PostType),
});

export const bookmarksSchema = z.object({
	count: z.number(),
	items: z.array(bookmarkSchema),
});

export type Bookmark = z.infer<typeof bookmarkSchema>;

export type BookmarksResponse = z.infer<typeof bookmarksSchema>;
